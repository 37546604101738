import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import MainScreen from "../components/Pages/LogisticPage/MainScreen";
import LogisticPage from "../components/Pages/LogisticPage/LogisticPage";
import useLanguage from "../components/Hooks/useLanguage";

const Logistic = ({ data }) => {
  const langToggle = useLanguage;
  const seo = data.strapiLogistic.seo;
  const homeTitle = data.strapiLogistic.title;
  const bg = data.strapiLogistic.image;
  const content = data.strapiLogistic.content;
  const fuel = data.strapiLogistic.fuel;
  const logistics = data.strapiLogistic.logistic;
  return (
    <Layout>
      <SEO
        title={langToggle(
          homeTitle.title_ua,
          homeTitle.title_ru,
          homeTitle.title_en
        )}
        description={langToggle(
          seo.seo_description_ua,
          seo.seo_description_ru,
          seo.seo_description_en
        )}
        keywords={[
          langToggle(
            seo.seo_keywords_ua,
            seo.seo_keywords_ru,
            seo.seo_keywords_en
          ),
        ]}
      />
      <MainScreen homeTitle={homeTitle} bg={bg} />
      <LogisticPage content={content} fuel={fuel} logistic={logistics} />
    </Layout>
  );
};

export default Logistic;

export const query = graphql`
  query LogisticQuery {
    strapiLogistic {
      seo {
        seo_description_ua
        seo_description_ru
        seo_description_en
        seo_keywords_ua
        seo_keywords_ru
        seo_keywords_en
      }
      title {
        title_ua
        title_ru
        title_en
        sub_title_ua
        sub_title_ru
        sub_title_en
      }
      image {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        content_ua
        content_ru
        content_en
      }
      fuel {
        title_ua
        title_ru
        title_en
        content_ua
        content_ru
        content_en
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      logistic {
        content_ua
        content_ru
        content_en
      }
    }
  }
`;
