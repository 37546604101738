import React from "react";
import Img from "gatsby-image";
import useLanguage from "../../Hooks/useLanguage";

const ReactMarkdown = require("react-markdown");

const LogisticPage = ({ content, fuel, logistic }) => {
  const langToggle = useLanguage;
  return (
    <section className="section-logistic">
      <div className="container-fluid">
        <h3 className="h3">
          {langToggle(
            content.content_ua,
            content.content_ru,
            content.content_en
          )}
        </h3>
        <div className="logistic-content">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-7 col-12">
              <h3 className="h3">
                {langToggle(fuel.title_ua, fuel.title_ru, fuel.title_en)}
              </h3>
              <ReactMarkdown
                source={langToggle(
                  fuel.content_ua,
                  fuel.content_ru,
                  fuel.content_en
                )}
                className="content"
              />
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
              <Img
                fluid={fuel.image.childImageSharp.fluid}
                className="content-img"
              />
            </div>
          </div>
        </div>
        <div className="logistic-points">
          <div className="row">
            {logistic.map((item, index) => {
              return (
                <div key={index} className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <p className="logistic-points_content">
                    {langToggle(
                      item.content_ua,
                      item.content_ru,
                      item.content_en
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogisticPage;
